const __request = require(`./__request/__request_contentType_json`);
// 重置员工密码：http://219.128.52.2:18765/doc.html#/haolv-biz/t-us-staff-controller/reSetPasswordUsingPOST
const consumer_web_staff_reSetPassword = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/web/staff/reSetPassword',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_web_staff_reSetPassword;