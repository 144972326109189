// consumer_country_queryCountryTwoWord
const __request = require(`./__request/__request_contentType_json`)
const consumer_country_queryCountryTwoWord = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: '/consumer/country/queryCountryTwoWord',
        data: data
    }
    return __request(pParameter)
}
export default consumer_country_queryCountryTwoWord
