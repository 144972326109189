// consumer_web_staff_detail 获取员工详情
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://219.128.52.2:18765/doc.html#/haolv-consumer/t-us-staff-controller/detailUsingPOST
const consumer_web_staff_detail = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/web/staff/detail',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_web_staff_detail;