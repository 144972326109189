export default {
    name: 'searchBox',
    data () {
        return {
            inputValue: this.value
        }
    },
    props: {
        value: {
            type: String
        },
        placeholder: {
            type: String
        }
    },
    components: {},
    created () {},
    mounted () {},
    activated () {
    },
    deactivated() {},
    destroyed () {},
    watch: {
        value(newVal) {
            this.inputValue = newVal
        },
        inputValue(newVal) {
            this.$emit('input', newVal)
        },
    },
    computed: {},
    filters: {},
    methods: {
        searchFocus(val) {
            this.$emit('focus', val)
        },
        searchBlur(val) {
            this.$emit('blur', val)
        },
        searchChange(val) {
            this.$emit('change', val)
        }
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/