const __request = require(`./__request/__request_contentType_json`);
// 获取公司职位列表全部接口文档地址：
// http://192.168.0.13:8765/doc.html#/haolv-consumer/t-us-position-controller/getPositionAllUsingPOST
const consumer_web_position_getPositionAll = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/web/position/getPositionAll',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_web_position_getPositionAll;